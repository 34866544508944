import React from 'react';
import { CSSProps, TKUIWithStyle } from "tripkit-react/dist/jss/StyleHelper";
import TKStateUrl from "tripkit-react/dist/state/TKStateUrl";
import LatLng from "tripkit-react/dist/model/LatLng";
import TKRoot from "tripkit-react/dist/config/TKRoot";
import TKUITripPlanner from "tripkit-react/dist/trip-planner/TKUITripPlanner";
import { buildConfig } from './config';

export interface IClientProps extends TKUIWithStyle<IStyle, IProps> {
}

export interface IStyle {
    main: CSSProps<IProps>;
}

interface IProps { }

const apiKeyInUrl = new URLSearchParams(window.location.search).get("apiKey");

const TripPlanerApp: React.FunctionComponent<IProps> = (props: IProps) => {

    let userLocationPromise = (window as any).tKUserLocationPromise ?
        (window as any).tKUserLocationPromise
            .then((userCoords: [number, number]) => LatLng.createLatLng(userCoords[0], userCoords[1])) : undefined;

    if (window.location.pathname === '/world') {
        window.history.replaceState(null, '', '/');
        userLocationPromise = Promise.reject();
    }

    const config = { ...buildConfig(), ...apiKeyInUrl && { apiKey: apiKeyInUrl } };

    // Obs: OptionsProvider used here and inside TKRoot keep their values at sync due to OptionsProvider subscribing to
    // OptionsData changes (See OptionsProvider:36). Try to avoid this so can remove that subscription.
    return (
        <TKRoot config={config}>
            <TKUITripPlanner userLocationPromise={userLocationPromise} />
            <TKStateUrl />
        </TKRoot>
    );
};

export default TripPlanerApp;