import React from 'react';
import { overrideClass } from "tripkit-react/dist/jss/StyleHelper";
import { ReactComponent as IconReport } from '../images/icon-usersnap.svg';
import RequestSupportAction from "../../../../feedback/RequestSupportAction";
import TKErrorHelper from "tripkit-react/dist/error/TKErrorHelper";
import { ERROR_ROUTING_NOT_SUPPORTED, ERROR_DESTINATION_OUTSIDE_COVERAGE } from "tripkit-react/dist/error/TKErrorHelper";
import TKStateConsumer from "tripkit-react/dist/config/TKStateConsumer";
import { TKState } from "tripkit-react/dist/config/TKState";
import { TKUITimetableViewProps } from "tripkit-react/dist/service/TKUITimetableView";
import { TKUIMapViewProps } from "tripkit-react/dist/map/TKUIMapView";
import TKGeocodingOptions from "tripkit-react/dist/geocode/TKGeocodingOptions";
import RegionsData from "tripkit-react/dist/data/RegionsData";
import OptionsData from "tripkit-react/dist/data/OptionsData";
import { loadTripState } from "../../../../options/TGUILoadTripsView";
import { ReactComponent as TripgoLogo } from '../images/logo-tripgo.svg';
import { ReactComponent as TripgoLogoDark } from '../images/logo-tripgo-dark.svg';
import Environment from "tripkit-react/dist/env/Environment";
import { default as TKPeliasGeocoder } from "tripkit-react/dist/geocode/PeliasGeocoder";
import { TKUIRoutingResultsViewProps } from "tripkit-react/dist/trip/TKUIRoutingResultsView";
import { TKError } from "tripkit-react/dist/error/TKError";
import TKUIButton from "tripkit-react/dist/buttons/TKUIButton";
import { TKUIConfig } from 'tripkit-react/dist/config/TKUIConfig';
import TGUICityLocations from '../../../../coverage/TGUICityLocations';
import TKDefaultGeocoderNames from 'tripkit-react/dist/geocode/TKDefaultGeocoderNames';
import { RoutingResultsContext } from 'tripkit-react/dist/trip-planner/RoutingResultsProvider';
import { TKUILocationSearchRaw } from 'tripkit-react/dist/query/TKUILocationSearch';
import { TKPropsOverride } from 'tripkit-react/dist/config/TKConfigHelper';
import { feedbackTextFromState } from 'tripkit-react/dist/feedback/TKUIReportBtn';
import { TripGoApiHeadersMap } from 'tripkit-react/dist/api/TripGoApi';
import TKLeafletLayer from 'tripkit-react/dist/map/TKLeafletLayer';
import TKMapboxGLLayer from 'tripkit-react/dist/map/TKMapboxGLLayer';
import { LatLng, TKUserProfile } from 'tripkit-react';

export const buildConfig: (options?: { renderCustomSettings?: () => JSX.Element, renderMenuItemsBuilder?: (onRequestClose: () => void) => (defaultItems: JSX.Element[]) => React.ReactNode, hideSearch?: boolean }) => TKUIConfig =
    ({ renderCustomSettings, renderMenuItemsBuilder, hideSearch } = {}) => {

        const userProfile = OptionsData.instance.get();

        const analyticsConfig = Environment.isBeta() ? {
            google: {
                tracker: {
                    trackingId: "G-RJYENKG0BE"
                }
            }
        } : undefined;

        const geocodeEarth = new TKPeliasGeocoder({
            server: "https://api.geocode.earth/v1",
            apiKey: "ge-1943f52b819ee339",
            resultsLimit: 5,
            restrictToBounds: userProfile.customData?.restrictToCoverageBounds
        });

        const onReport = state => {
            const reportEmail = "mauro+tripgo@skedgo.com";
            const subject = encodeURIComponent(`Report from ${window.location.hostname}`);
            const body = encodeURIComponent(feedbackTextFromState(state));
            window.open(`mailto:${reportEmail}?subject=${subject}&body=${body}`);
        };

        // const apiHeaders: TripGoApiHeadersMap = {
        //     "tsp-user-id": "143849",
        //     "tsp-tenant-id": "11116"
        // }

        // const apiHeaders = undefined

        let defaultUserProfile: TKUserProfile | undefined = undefined;
        const hostname = (window as any).location.hostname;
        const isSGFleet = hostname.includes("sgfleet.");
        if (isSGFleet || Environment.isBeta()) {
            defaultUserProfile = OptionsData.instance.get();
            if (defaultUserProfile.customData?.sendSGFleetHeaders === undefined) {  // If not set, default to true
                defaultUserProfile.customData = {
                    ...defaultUserProfile.customData,
                    sendSGFleetHeaders: true    // Default to true
                };
            }
        }

        const regionsFilterRegex = userProfile.customData?.regionsFilterRegex;
        if (regionsFilterRegex) {
            RegionsData.regionsFilterRegex = new RegExp(regionsFilterRegex);
        }
        let initViewport: { center: LatLng, zoom: number } | undefined = undefined;
        let userLocationPromise;
        try {
            const initCoords = userProfile.customData?.initCoords.split(",").map(parseFloat);
            if (initCoords) {
                initViewport = { center: LatLng.createLatLng(initCoords[0], initCoords[1]), zoom: 13 };
                userLocationPromise = Promise.reject("Init viewport set");
            }
        } catch (e) {
            console.error(e);
        }

        const restrictToCoverageBounds = userProfile.customData?.restrictToCoverageBounds;

        const apiHeaders: (params: { defaultHeaders: TripGoApiHeadersMap, requestUrl: URL }) => TripGoApiHeadersMap | undefined = ({ requestUrl, defaultHeaders }) => {
            if (defaultHeaders['x-tripgo-key'] !== "037e73b88b8138af1fd8dcea783bb64c"   // iOS beta key
                && defaultHeaders['x-tripgo-key'] !== "bf3d2c318292c7769e8c987b06b5113d") { // SGFleet key
                return undefined;
            }
            // Don't send headers for locationInfo.json requests for PT stops, since endpoint fails. Workaround until BE is fixed.
            if ((requestUrl.pathname.includes("locationInfo.json") && requestUrl.searchParams.get("identifier")?.startsWith("pt_pub"))) {
                return undefined;
            }
            if (userProfile.customData?.sendSGFleetHeaders) {
                return ({
                    "tsp-user-id": "143849",
                    "tsp-tenant-id": "11116"
                });
            }
            return undefined;
        };

        return ({
            apiKey: '790892d5eae024712cfd8616496d7317',
            apiHeaders,
            theme: {
                fontFamily: 'ProximaNova, sans-serif'
            },
            // isDarkDefault: true,
            initViewport,
            analytics: analyticsConfig,
            i18n: (window as any).tKI18nPromise,
            geocoding: (defaultOptions: TKGeocodingOptions) => {
                const defaultGeocoders = { ...defaultOptions.geocoders };
                if (userProfile.customData?.recentGeocoder === false) {
                    delete defaultGeocoders[TKDefaultGeocoderNames.recent];
                }
                return ({
                    geocoders: {
                        ...defaultGeocoders,
                        'geocodeEarth': geocodeEarth
                    }
                });
            },
            restrictToCoverageBounds,
            defaultUserProfile,
            resetUserProfile: !!defaultUserProfile,
            TKUIReportBtn: {
                props: {
                    renderIcon: () => <IconReport />,
                    onClick: onReport
                },
                styles: {
                    main: overrideClass({ height: '26px', padding: '3px' })
                }
            },
            TKUIMapView: {
                props: (props: TKUIMapViewProps) => {
                    const { trip, tripSegment } = props;
                    const mapTiles = tripSegment ? tripSegment.mapTiles : trip?.mainSegment?.mapTiles;
                    const renderLayer = mapTiles ?
                        () =>
                            <TKLeafletLayer
                                attribution={mapTiles.sources
                                    .map(source => `<a href=${source.provider.website} tabindex='-1'>${source.provider.name}</a>`)
                                    .join(" | ")}
                                url={mapTiles.urlTemplates[0]}
                            // url={"https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"}
                            // url={"https://b.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"}
                            // url={"http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=aYTqZORZ7FFwqoFZ7c4j&app_code=qUK5XVczkZcFESPnGPFKPg"}
                            // url={props.theme.isLight ?
                            //     "https://api.mapbox.com/styles/v1/mgomezlucero/cjvp9zm9114591cn8cictke9e/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWdvbWV6bHVjZXJvIiwiYSI6ImNsbXJ1Y2c4MzA5ajgya3BncmlmOXdvZHYifQ.9ogoBJWetHkMG4V0QL9zPw" :
                            //     "https://api.mapbox.com/styles/v1/mgomezlucero/ckbmm6m0w003e1hmy0ksjxflm/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWdvbWV6bHVjZXJvIiwiYSI6ImNsbXJ1Y2c4MzA5ajgya3BncmlmOXdvZHYifQ.9ogoBJWetHkMG4V0QL9zPw"}
                            /> :
                        () =>
                            <TKMapboxGLLayer
                                accessToken={"pk.eyJ1IjoibWdvbWV6bHVjZXJvIiwiYSI6ImNsbXJ1Y2c4MzA5ajgya3BncmlmOXdvZHYifQ.9ogoBJWetHkMG4V0QL9zPw"}
                                style={props.theme.isLight ?
                                    "mapbox://styles/mgomezlucero/ckjliu0460xxh1aqgzzb2bb34" :
                                    "mapbox://styles/mgomezlucero/ckbmm6m0w003e1hmy0ksjxflm"}
                                attribution={"<a href='http://osm.org/copyright' tabindex='-1'>OpenStreetMap</a>"}
                            />;
                    return ({
                        children: <TGUICityLocations maxZoom={10} />,
                        disableMapClick: zoom => (zoom ?? 0) < 6,
                        renderLayer
                    });
                }
            },
            TKUIRoutingResultsView: {
                props: (props: TKUIRoutingResultsViewProps) => ({
                    errorActions: (error: TKError, defaultActions: JSX.Element[]) => {
                        let errorActions = defaultActions;
                        const query = props.query;
                        if (TKErrorHelper.hasErrorCode(error, ERROR_ROUTING_NOT_SUPPORTED) ||
                            TKErrorHelper.hasErrorCode(error, ERROR_DESTINATION_OUTSIDE_COVERAGE)) {
                            errorActions = [
                                <RequestSupportAction
                                    actionTitle={props.t("Request.support")}
                                    formTitle={"Request route"}
                                    formMessage={"Please support routing between " + query!.from!.address + " and " + query!.to!.address + "\n\n" +
                                        "From coordinate: " + query!.from!.lat + ", " + query!.from!.lng + "\n" +
                                        "To coordinate: " + query!.to!.lat + ", " + query!.to!.lng}
                                    key={"Request.support"} />
                            ].concat(errorActions);
                        } else {
                            errorActions = [
                                <TKStateConsumer>
                                    {(state: TKState) => <TKUIButton
                                        text={props.t("Report.Problem")}
                                        onClick={() => onReport(state)}
                                        key={"Report.Problem"}
                                    />}
                                </TKStateConsumer>
                            ].concat(errorActions);
                        }
                        return errorActions;
                    }
                })
            },
            TKUITimetableView: {
                props: (props: TKUITimetableViewProps) => ({
                    errorActions: (error: TKError) => ([
                        <TKStateConsumer key={"Report.Problem"}>
                            {(state: TKState) => <TKUIButton
                                text={props.t("Report.Problem")}
                                onClick={() => onReport(state)}
                            />}
                        </TKStateConsumer>
                    ].concat(props.errorActions ? props.errorActions(error) : []))
                })
            },
            TKUIProfileView: {
                props: renderCustomSettings ? { customSettings: renderCustomSettings } : undefined
            },
            TKUITripPlanner: {
                props: (props) => ({
                    renderTopRight: props.landscape ?
                        (props.theme.isDark ? () => <TripgoLogoDark /> : () => <TripgoLogo />) : undefined,
                    hideSearch,
                    userLocationPromise
                })
            },
            TKUISidebar: {
                props: props => ({
                    renderLogo: () => <TripgoLogo />,
                    appStoreUrl: "https://apps.apple.com/au/app/tripgo/id533630842",
                    playStoreUrl: "https://play.google.com/store/apps/details?id=com.buzzhives.android.tripplanner",
                    menuItems: renderMenuItemsBuilder?.(props.onRequestClose)
                })
            },
            TKUILocationSearch: {
                // Need to override render, instead of just props, since the latter don't
                // include viewport, so need to use RoutingResultsContext.
                render: props =>
                    <RoutingResultsContext.Consumer>
                        {({ viewport, onPreChange }) =>
                            <TKPropsOverride
                                componentKey={'TKUILocationBox'}
                                propsOverride={props => (viewport?.zoom ?? 0) < 4 ? {
                                    iconEmpty: undefined,
                                    placeholder: props.t("Search.for.a.city"),
                                    geocodingOptions: { ...props.geocodingOptions, geocoders: { [TKDefaultGeocoderNames.cities]: props.geocodingOptions.geocoders[TKDefaultGeocoderNames.cities] } },
                                    selectOnBlur: false
                                } : undefined}
                            >
                                <TKUILocationSearchRaw
                                    {...props}
                                    {...(viewport?.zoom ?? 0) < 4 && {
                                        onDirectionsClicked: undefined,
                                        onShowSideBarClicked: undefined,
                                        // Not only avoid effect on autocomplete item highlight, but
                                        // remove the current highlighted item, if any.
                                        onPreChange: () => onPreChange?.(false, undefined)
                                    }}
                                />
                            </TKPropsOverride>
                        }
                    </RoutingResultsContext.Consumer>
            },
            TKUIMapLocationIcon: {
                // styles: {
                //     main: {
                //         background: 'orange'
                //     }
                // },
                // render: ({from}) => <div>{from ? "hola" : "chau"}</div>
            },
            // ...!Environment.isProd() && {
            //     TKUIVehicleAvailability: {
            //         props: {
            //             onBookClick: ({ bookingURL, vehicleId, bookingStart, bookingEnd }) => {
            //                 window.open(bookingURL + `${bookingURL.includes("?") ? "&" : "?"}identifier=${vehicleId}&start=${bookingStart}&end=${bookingEnd}`, '_blank');
            //             }
            //         }
            //     }
            // },
            onInitState: (tKState: TKState) => {
                RegionsData.instance.requireRegions().catch(error => {
                    const userProfile = OptionsData.instance.get();
                    if (error.message.includes("Invalid API key")) {
                        const invalidDevKey = userProfile.customData && userProfile.customData.apiKey;
                        if (invalidDevKey) {
                            if (window.confirm("Invalid API key: " + invalidDevKey + ". Will reset to production key and reload.")) {
                                delete userProfile.customData.apiKey;
                                OptionsData.instance.save(userProfile);
                                window.location.reload();
                            }
                        }
                    } else {
                        const invalidServer = userProfile.customData && userProfile.customData.server;
                        if (invalidServer) {
                            if (window.confirm("There was a problem loading regions from server: " + invalidServer + ". Will reset to production and reload.")) {
                                delete userProfile.customData.server;
                                OptionsData.instance.save(userProfile);
                                window.location.reload();
                            }
                        }
                    }
                    throw error;
                });
                document.addEventListener("keydown", (zEvent: any) => {
                    if (zEvent.shiftKey && zEvent.metaKey && zEvent.key === "v") {
                        navigator.clipboard && navigator.clipboard.readText && navigator.clipboard.readText().then(t => {
                            loadTripState(t, tKState);
                        }).catch((e) => console.log(e));
                        zEvent.preventDefault();
                    }
                });
            }
        });
    };