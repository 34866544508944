import React, { Fragment, useContext, useEffect, useState } from 'react';
import genStyles from 'tripkit-react/dist/css/GenStyle.css';
import { renderToStaticMarkup, TKUIWithClasses, withStyles } from 'tripkit-react/dist/jss/StyleHelper';
import { TKUITheme } from 'tripkit-react/dist/jss/TKUITheme';
import City from 'tripkit-react/dist/model/location/City';
import { Marker, L, Popup } from 'tripkit-react/dist/map/TKUIMapView';
import RegionsData from 'tripkit-react/dist/data/RegionsData';
import { RoutingResultsContext } from 'tripkit-react/dist/trip-planner/RoutingResultsProvider';
import { ReactComponent as IconCity } from '../images/ic-city.svg';
import { ReactComponent as IconGlass } from '../images/ic-glass-zoom.svg';
import classnames from 'classnames';
import { resetStyles } from 'tripkit-react/dist/css/ResetStyle.css';
import DeviceUtil from 'tripkit-react/dist/util/DeviceUtil';
import { useIsMounted } from 'tripkit-react/dist/util/ReactUtil';

const tGUICityLocationsPropsDefaultStyle = (theme: TKUITheme) => ({
    city: {
        background: '#23b15e',
        ...genStyles.borderRadius(50, '%'),
        ...genStyles.flex,
        ...genStyles.alignCenter,
        '& path': {
            fill: 'white'
        },
        '& svg': {
            width: '100%',
            height: '100%'
        },
        boxSizing: 'border-box'
    },
    cityBright: {
        background: '#00be5e',   // The one on skedgo.com
    },
    highlight: {
        background: '#00be5e',
        boxShadow: '0px 0px 4px 3px #00be5e!important'
    },
    popup: {
        ...genStyles.flex,
        ...genStyles.alignCenter,
        padding: '5px 10px'
    },
    glassIcon: {
        ...resetStyles.button,
        margin: '1px 0 -1px 8px',
        '& svg': {
            height: '22px',
            width: '22px'
        },
        '& path': {
            fill: theme.colorPrimary
        }
    }
});

type IStyle = ReturnType<typeof tGUICityLocationsPropsDefaultStyle>

interface IProps extends TKUIWithClasses<IStyle, IProps> {
    values?: City[];
    onSelect?: (city: City) => void;
    preselect?: City;
    onPreselect?: (city: City | undefined) => void;
    maxZoom?: number;
}

const TGUICityLocations: React.FunctionComponent<IProps> =
    ({ values, onSelect, preselect: preselectProp, onPreselect, classes, maxZoom }) => {
        const [cities, setCities] = useState<City[] | undefined>(undefined);
        const [preselectState, setPreselect] = useState<City | undefined>(undefined);
        const { viewport, map } = useContext(RoutingResultsContext);
        const isMounted = useIsMounted();
        useEffect(() => {
            if (!values) {
                RegionsData.instance.requireRegions()
                    .then(() => isMounted() && setCities(RegionsData.instance.getCities()));
            }
        }, []);
        if (!onSelect) {
            onSelect = city => map?.setViewport(city, 13);
        }
        const zoom = viewport?.zoom ?? 0;
        if (maxZoom !== undefined && zoom > maxZoom) {
            return null;
        }
        const showIcon = zoom > 5;
        const showBorder = zoom > 3;
        const size = showIcon ? 20 : showBorder ? 8 : 5;
        return (
            <Fragment>
                {(values ?? cities)?.map((city, i) =>
                    <Marker
                        position={city}
                        icon={L.divIcon({
                            html: renderToStaticMarkup(
                                <div
                                    className={classnames(classes.city, !showBorder && classes.cityBright, city === (onPreselect ? preselectProp : preselectState) && classes.highlight)}
                                    style={{ width: size, height: size, ...(showBorder) && { border: '1px solid white' }, ...showIcon && { padding: 2 } }}>
                                    {showIcon && <IconCity />}
                                </div>
                            ),
                            iconSize: [size, size],
                            iconAnchor: showIcon ? [size / 2, size / 4] : [Math.floor(size / 2), Math.floor(size / 2)],
                            className: ""
                        })}
                        riseOnHover={true}
                        keyboard={false}
                        key={i}
                        onclick={!DeviceUtil.isTouch() ? () => onSelect?.(city) : undefined}
                        onmouseover={!DeviceUtil.isTouch() ?
                            e => {
                                e.target.openPopup();
                                (onPreselect ?? setPreselect)?.(city);
                            } :
                            undefined}
                        onmouseout={!DeviceUtil.isTouch() ?
                            e => {
                                setTimeout(() => e.target.closePopup(), 1000);
                                (onPreselect ?? setPreselect)?.(undefined);
                            } :
                            undefined}
                    >
                        <Popup
                            offset={[0, showIcon ? size / 4 : Math.floor(size / 2)]}
                            closeButton={false}
                            // TODO: disabled auto pan to fit popup on open since it messes with viewport. Fix it.
                            autoPan={false}
                        >
                            <div className={classes.popup}>
                                {city.name}
                                {DeviceUtil.isTouch() &&
                                    <button className={classes.glassIcon} onClick={() => onSelect?.(city)}>
                                        <IconGlass />
                                    </button>}
                            </div>
                        </Popup>
                    </Marker>)}
            </Fragment>
        );
    }

export default withStyles(TGUICityLocations, tGUICityLocationsPropsDefaultStyle);