(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-leaflet"), require("leaflet"), require("mapbox-gl"), require("mapbox-gl-leaflet"));
	else if(typeof define === 'function' && define.amd)
		define(["react-leaflet", "leaflet", "mapbox-gl", "mapbox-gl-leaflet"], factory);
	else if(typeof exports === 'object')
		exports["ReactMapboxGlLeaflet"] = factory(require("react-leaflet"), require("leaflet"), require("mapbox-gl"), require("mapbox-gl-leaflet"));
	else
		root["ReactMapboxGlLeaflet"] = factory(root["react-leaflet"], root["leaflet"], root["mapbox-gl"], root["mapbox-gl-leaflet"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 